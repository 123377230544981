<template>
  <div class="operating_container">
    <div class="crumbs operating_item">
      <el-breadcrumb separator-icon="ArrowRight">
        <el-breadcrumb-item :to="{ path: '/workbench' }">
          <i class="iconfont icon-zhuye"></i
        ></el-breadcrumb-item>
        <el-breadcrumb-item>交易管理</el-breadcrumb-item>
        <el-breadcrumb-item>售电持仓看板</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="operating_item flex_between">
      <div class="flex_start operation">
        <div class="flex_start">
          <span>查询方式：</span>
          <el-radio-group v-model="tabs" class="tab_time">
            <el-radio-button
              :label="radio.value"
              v-for="(radio, indexRadio) in valList.data"
              :key="indexRadio"
              >{{ radio.label }}</el-radio-button
            >
          </el-radio-group>
          <el-date-picker v-model="dateTime" type="date" placeholder="请选择" />
          <el-button type="primary" @click="getData" class="search"
            >查询</el-button
          >
        </div>
      </div>
    </div>

    <div class="operating_box">
      <div class="forecast">
        <div class="forecast_container">
          <div
            class="forecast_item"
            v-for="(item, index) in forecastMsg.data"
            :key="index"
          >
            <div>{{ item.label }}</div>
            <div>{{ item.value }}</div>
          </div>
        </div>
        <div class="forecast_echarts">
          <div class="flex_between">
            <el-tabs
              v-model="paneActive"
              class="forecast_tabs"
              @tab-click="getData"
            >
              <el-tab-pane
                :label="item.name"
                :name="item.id"
                v-for="(item, index) in paneList.data"
                :key="index"
              ></el-tab-pane>
            </el-tabs>

            <el-radio-group v-model="forecastType" @change="getData">
              <el-radio-button label="预测" />
              <el-radio-button label="实际" />
            </el-radio-group>
          </div>

          <div class="" id="sales_1" ref="sales_1"></div>

          <div class="" id="sales_2" ref="sales_2"></div>
        </div>
      </div>

      <div class="similar"></div>
    </div>
  </div>
</template>

<script>
import { reactive, ref, getCurrentInstance, nextTick, onMounted } from "vue";
export default {
  name: "electricitySale",
  setup() {
    const { proxy } = getCurrentInstance(); // proxy是组件实例化对象

    let dateTime = ref(new Date());

    let valList = reactive({
      data: [
        { label: "年", value: 1 },
        { label: "月", value: 2 },
        { label: "段", value: 3 },
      ],
    });
    let tabs = ref(1);

    let forecastMsg = reactive({
      data: [
        { label: "统调预测（MWh）", value: "10,908,830" },
        { label: "西电预测（MWh）", value: "2,238,867" },
        { label: "B类预测（MWh）", value: "6,867,745" },
        { label: "平均温度（C°）", value: "28.3" },
      ],
    });
    let forecastType = ref("预测");

    let paneList = reactive({
      data: [
        { id: 1, name: "测算售电盈亏" },
        { id: 2, name: "持仓情况" },
      ],
    });

    let paneActive = ref(1);

    let forecast = reactive({
      options: {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
          },
          formatter: function (params) {
            let back = params[0].name;
            for (let i = 0, l = params.length; i < l; i++) {
              back +=
                "<br/>" +
                params[i].marker +
                params[i].seriesName +
                "  " +
                params[i].value +
                "万";
            }
            return back;
          },
        },

        dataZoom: [
          {
            type: "inside", //slider表示有滑动块的，inside表示内置的
          },
        ],
        toolbox: {
          show: true,
          feature: {
            saveAsImage: {
              backgroundColor: "#0b183c",
              type: "png",
              padding: 25,
            },
          },
          right: "5%",
          iconStyle: {
            borderColor: "#FFFFFF",
          },
          //   'iconStyle.color': '#FFFFFF',
          //    iconStyle: {
          //        color: '#FFFFFF',
          //        borderColor: "#94d6da"

          //             },
        },
        legend: {
          type: "scroll",
          icon: "rectangle", //区域块
          data: [],
          textStyle: {
            color: "#FFFFFF",
          },
        },
        grid: {
          //   top:'8%',
          left: "2%",
          right: "2%",
          bottom: "3%",
          containLabel: true,
        },

        xAxis: [
          {
            type: "category",
            data: [],
            axisTick: {
              alignWithLabel: true,
            },

            boundaryGap: false,
            axisLabel: {
              //轴上的字 字体、样式
              interval: 0, //代表显示所有x轴标签显示
              rotate: 45, //代表逆时针旋转45度
              textStyle: {
                color: "#FFFFFF",
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "电量：万kWh",
            nameTextStyle: {
              color: "#FFFFFF",
              align: "left",
            },
            axisLine: { onZero: false, show: true },
            axisLabel: {
              //轴上的字 字体、样式
              textStyle: {
                color: "#FFFFFF",
              },
              formatter: "{value} ",
            },
            splitLine: {
              //修改背景线条样式
              show: true, //是否展示
              lineStyle: {
                color: "#8291A9", //线条颜色
                type: "dashed", //线条样式，默认是实现，dashed是虚线
              },
            },
          },
        ],
        series: [],
      },
    });

    const forecastChart = async (target, serieList) => {
      let mapData = JSON.parse(JSON.stringify(forecast.options));
      let seriesMap = [];
      serieList.forEach((val) => {
        seriesMap.push({
          type: "line",
          name: val.name,
          barWidth: 14,
          data: [],
          showSymbol: false,
          smooth: false,
          itemStyle: {
            normal: {
              color: random(),
            },
          },
        });
        mapData.legend.data.push(val.name);
      });

      console.log(mapData);
      console.log(serieList);
      await nextTick(() => {
        // 渲染图表
        const chart = proxy.$echarts.init(document.getElementById(target));
        let titles = [
          "00:00",
          "01:15",
          "02:30",
          "03:45",
          "04:00",
          "05:10",
          "06:30",
          "07:45",
          "08:00",
          "09:15",
          "10:30",
          "11:45",
          "12:00",
          "13:15",
          "14:30",
          "15:45",
          "16:00",
          "17:15",
          "18:30",
          "19:45",
          "20:00",
          "21:15",
          "22:30",
          "23:45",
        ];
        mapData.series = seriesMap;
        //   let array =
        for (let t = 0; t < seriesMap.length; t++) {
          for (let i = 0; i < titles.length; i++) {
            mapData.series[t].data.push(Math.trunc(Math.random() * 100));
          }
        }

        mapData.xAxis[0].data = titles;
        console.log(mapData);
        chart.setOption(mapData,true);
        window.onresize = () => {
          chart.resize();
        };
      });
    };

    const random = () => {
      let r = Math.floor(Math.random() * 255);
      let g = Math.floor(Math.random() * 255);
      let b = Math.floor(Math.random() * 255);
      return `rgb(${r}, ${g}, ${b})`;
    };

    const getData = () => {
      let list = [
        { name: "中长期电量" },
        { name: "测算客户电量" },
        { name: "实时电量" },
      ];
      let list2 = [{ name: "中长期均价" }];
      forecastChart("sales_1", list);
      forecastChart("sales_2", list2);
    };

    onMounted(() => {
      nextTick(() => {
        getData();
      });
    });

    return {
      dateTime,
      valList,
      tabs,
      forecastMsg,
      forecastType,
      paneList,
      paneActive,
      forecastChart,
      forecast,
      random,
      getData,
    };
  },
};
</script>

<style lang="scss" scoped>
.operating_box {
  display: flex;
  justify-content: space-between;
}
.forecast {
  width: 100%;
}

.operating_container {
  color: #ffffff;
  display: flex;
  flex-direction: column;
  .operating_item {
    width: 100%;
    margin-bottom: 24px;

    .operation {
      font-size: 14px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;

      &:deep(.el-input__wrapper) {
        background: #1f2935;
        box-shadow: none;
      }
      &:deep(.el-input__inner) {
        color: #ffffff;
      }
    }
  }
  .crumbs {
    font-size: 14px;
    &:deep(.el-breadcrumb__item:last-child .el-breadcrumb__inner) {
      color: #ffffff;
    }
  }
}
.forecast_container {
  width: 70%;
  display: flex;
  justify-content: space-between;
}
.forecast_item {
  width: 220px;
  background: #0b183c;
  box-shadow: inset 0px 0px 10px 0px #3767ad;
  display: flex;
  align-content: flex-start;
  flex-wrap: wrap;
  padding: 24px 21px;
  border-radius: 8px;

  & > div {
    width: 100%;

    font-size: 14px;
    font-family: Poppins-Regular, Poppins;
    font-weight: 400;
    color: #ffffff;
  }
}
.forecast_item > div:last-child {
  font-size: 36px;
  font-family: D-DIN-Bold, D-DIN;
  font-weight: bold;
  color: #36d1ff;
  margin-top: 12px;
}
.forecast_echarts {
  width: 100%;
  background: #0b183c;
  box-shadow: inset 0px 0px 10px 0px #3767ad;
  padding: 24px;
  box-sizing: border-box;
  margin-top: 24px;
  border-radius: 8px;
}
.forecast_tabs {
  &:deep(.el-tabs__item) {
    color: #ffffff;
  }
  &:deep(.el-tabs__active-bar) {
    background: #36d1ff;
  }

  &:deep(.el-tabs__nav-wrap::after) {
    // width: 1000px;
    background: #8291a9;
    //  position: static !important;
  }
}
#sales_1 {
  width: 100%;
  height: 300px;
  margin-bottom: 25px;
}
#sales_2 {
  width: 100%;
  height: 300px;
}
.tab_time {
  margin: 0 15px;
}
.search {
  margin-left: 25px;
}
</style>
